



























































import {
  Component,
  Inject,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { Cell } from '@/models/entities/Cell';
import { trafficLightStatuses } from '@/types/Statuses';
import { AppStore } from '@/store/types/AppStore';
import { WampService } from '@/services/WampService';
import { Polygon } from '@/models/entities/Polygon';

@Component({})
export default class PolygonCellEditable extends Vue {
  @Prop({ required: true }) cell!: Cell;

  @Prop({ required: true }) polygon!: Polygon;

  @Prop({
    type: Boolean,
    default: false,
  })
  isHorizontal!: boolean;

  @Inject() appStore!: AppStore;
  @Inject() wampService!: WampService;

  trafficLightStatusesArray = Object.values(trafficLightStatuses);

  get payloadState() {
    return this.appStore.getters.payloadState;
  }

  get polygonId(): number {
    return this.polygon?.polygonId || 0;
  }

  @Watch('cell', { immediate: true })
  subscribe(newCell: Cell) {
    if (newCell) {
      this.appStore.actions.subscribePolygonUpdates([newCell]);
    }
  }

  clear() {
    this.cell.cellFeatures.forEach((cf) => {
      if (cf.isDisplay) {
        this.changePayloadStateDisplay(cf.cellFeatureId, '');
      } else if (cf.isTrafficLight) {
        this.changePayloadStateTrafficLight(cf.cellFeatureId, trafficLightStatuses[0]);
      } else {
        this.changePayloadStateCheckbox(cf.cellFeatureId, false);
      }
    });
  }

  mkFeatureUri(featureId: string, component: 'status' | 'commands'): string {
    return `polygon${this.polygonId}.cell${this.cell.sequentialNumber}.${featureId}.${component}`;
  }

  payloadValueDisplay(cfId: string) {
    const uri = this.mkFeatureUri(cfId, 'status');
    return this.payloadState[uri] || '';
  }

  payloadValueTrafficLight(cfId: string) {
    const uri = this.mkFeatureUri(cfId, 'status');
    const idx = this.payloadState[uri] || 0;
    return trafficLightStatuses[idx] || trafficLightStatuses[0];
  }

  payloadValueCheckbox(cfId: string) {
    const uri = this.mkFeatureUri(cfId, 'status');
    return this.payloadState[uri] === '1';
  }

  changePayloadStateDisplay(cfId: string, currentValue?: string) {
    const uri = this.mkFeatureUri(cfId, 'commands');
    this.wampService.publish(uri, currentValue);
  }

  changePayloadStateTrafficLight(cfId: string, currentValue?: string) {
    const uri = this.mkFeatureUri(cfId, 'commands');
    const idx = this.trafficLightStatusesArray.indexOf(currentValue || trafficLightStatuses[0]);
    this.wampService.publish(uri, idx);
  }

  changePayloadStateCheckbox(cfId: string, currentValue?: boolean) {
    const uri = this.mkFeatureUri(cfId, 'commands');
    this.wampService.publish(uri, currentValue ? 1 : 0);
  }
}
